import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import cuposBg from './img/cupos-bg.png'
import closeIcon from './img/close-icon.png';
import { cuposFormUrl } from './config'

let timeout: NodeJS.Timeout

const Overlay = styled.div`
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
`

const Close = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 8px;
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  top: 16px;
  left: 16px;

  img {
    filter: invert(1);
    width: 16px;
    height: 16px;
  }
`;

const ModalContainer = styled.div<{
  open: boolean,
}>`
  ${props => props.open ? 'display: flex;' : 'display: none;'}
`

const Modal = styled.div<{
  bg: string
}>`
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 200px;
  background-color: #fff;
  border-radius: 24px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-repeat: no-repeat;
  width: 600px;
  height: 500px;

  .btn {
    position: absolute;
    right: 24px;
    bottom: 120px;
  }

  .checkbox-container {
    position: absolute;
    bottom: 190px;
    right: 24px;
    display: flex;
    align-items: center;
    
    
    a {
      color: #FFF !important;
      font-weight: normal;
    }
    

    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #FFF;
      border-radius: 4px;
      margin-right: 8px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
    }

    input[type="checkbox"]:checked {
      background-color: #FFF;
    }

    input[type="checkbox"]:checked::before {
      content: '✔';
      color: #000;
      font-size: 14px;
      position: absolute;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 320px;

    .btn {
      bottom: 60px;
    }

    .checkbox-container {
      bottom: 125px;
      text-align: center;
    }
  }
`

const ModalCupos = () => {
  const [open, setOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false) // Estado del checkbox

  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setOpen(true)
    }, 1000)
  }, [])

  return (
    <ModalContainer open={open}>
      <Overlay onClick={() => setOpen(false)} />
      <Modal bg={cuposBg}>
        <Close onClick={() => setOpen(false)}>
          <img src={closeIcon} alt="close" />
        </Close>

        {/* Checkbox estilizado */}
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="acceptPolicies"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <label htmlFor="acceptPolicies">
            <span className="checkmark"></span>
            <a href='./docs/ACUERDO.pdf' target='_blank'> Aceptar políticas y condiciones</a>
          </label>
        </div>

        {/* Botón deshabilitado hasta que el checkbox esté marcado */}
        <a
          className="btn big"
          href={isChecked ? cuposFormUrl : undefined} // Solo permite navegar si está habilitado
          target={isChecked ? "_blank" : undefined}
          rel={isChecked ? "noopener noreferrer" : undefined}
          style={{
            pointerEvents: isChecked ? "auto" : "none", // Deshabilita clics cuando no está marcado
            opacity: isChecked ? 1 : 0.5, // Cambia la apariencia cuando está deshabilitado
          }}
        >
          Solicitar
        </a>
      </Modal>
    </ModalContainer>
  )
}

export default ModalCupos
